import React, { useState } from "react";
import { graphql, Link } from "gatsby";

import Page from "../../components/Page";
import SEO from "../../components/SEO";
import CopyToClipboardIcon from "../../components/Utils/CopyToClipboard";
import MarkdownRenderer from "../../components/MarkdownRenderer";
import { StaticImage } from "gatsby-plugin-image";

function LeftColumnItem({ title, description }) {
  const render = [];

  if (description.startsWith?.("http")) {
    render.push(
      <a href={description} target="_blank" rel="noopener noreferrer">
        External Website
      </a>
    );
  } else if (Array.isArray(description) && description.length === 2) {
    render.push(<div>{description[0]}</div>);
    render.push(<div>{`@${description[1]}`}</div>);
  } else {
    render.push(<span>{description}</span>);
  }

  return (
    <div className="flex flex-col capitalize text-md">
      <div className="text-white">{title}</div>
      <div className="text-blue-100">{render}</div>
    </div>
  );
}

// with copy to clipboard icon
function InstallationDescription({ installation, builtIn }) {
  return (
    <ul className="relative z-10 p-2 mt-4 bg-blue-600 rounded-lg shadow-lg w-80">
      <svg
        className="absolute z-0 h-4 text-blue-600 fill-current right-16 -top-3 phone-size:left-4 sm:left-auto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
      </svg>
      <li className="flex items-center justify-around p-1 pl-2 text-sm text-gray-600 rounded-full">
        <span className="font-mono text-white ">{installation}</span>
        {!builtIn && (
          <CopyToClipboardIcon
            onClick={() => navigator.clipboard.writeText(installation)}
          />
        )}
      </li>
    </ul>
  );
}

function Mode({ data, pageContext: { id, text } }) {
  const [showDescription, setShowDescription] = useState(false);
  const modesData = data.modesJson;

  // disable description on page click anywhere
  // useEffect(() => {
  //   const handleClick = (e) => {
  //     if (e.target.className === "page") {
  //       setShowDescription(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClick);
  //   return () => {
  //     document.removeEventListener("click", handleClick);
  //   };
  // }, []);
  return (
    <Page>
      <SEO title={modesData.title} />
      <main className="my-16">
        <div className="lg:grid lg:grid-cols-12 mb-20p md:px-10 lg:px-10 xl:px-0 phone-size:px-10">
          {/* INTRO */}
          <div className="hidden pl-4 lg:block xl:col-span-2 lg:col-span-2">
            <div className="flex flex-col space-y-24">
              <div className="w-12">
                <Link to="/modes">
                  <StaticImage
                    className=""
                    src="../../images/icon-large-arrow@3x.png"
                    alt={"icon"}
                  />
                </Link>
              </div>
              <div className="flex flex-col space-y-5">
                {["author", "repository", "license"].map((item) => (
                  <LeftColumnItem
                    key={item}
                    title={item}
                    description={modesData[item]}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="xl:col-span-10 lg:col-span-10">
            <div className="flex sm:flex-row sm:items-center sm:justify-between p-4 bg-blue-750 phone-size:flex-col">
              <div className="flex items-center">
                <div className="w-20">
                  <StaticImage
                    src="../../images/icon-ohif-modes@3x.png"
                    alt="icon"
                  />
                </div>
                <div className="flex flex-col pl-4">
                  <div className="text-lg text-blue-100 sm:block phone-size:hidden">
                    OHIF Modes
                  </div>
                  <div className="text-4xl text-white">{modesData.title}</div>
                </div>
              </div>
              <div
                className="relative flex items-center gap-2 pr-4 phone-size:mt-2 phone-size:ml-2"
                onClick={() => setShowDescription(!showDescription)}
              >
                <div className="cursor-pointer flex items-center gap-2 phone-size:mt-2 phone-size:ml-2">
                  <div className="w-7">
                    <StaticImage
                      src="../../images/install@2x.png"
                      alt="install icon"
                    />
                  </div>
                  <div className="text-lg text-blue-100">Install</div>
                </div>
                {showDescription && (
                  <div
                    className="absolute top-8 right-3 sm:left-auto phone-size:left-4"
                    onBlur={() => setShowDescription(false)}
                  >
                    <InstallationDescription
                      installation={`${modesData.installationCommand}`}
                      builtIn={modesData.builtIn}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="text-white">
              <MarkdownRenderer text={text} />
            </div>
          </div>
        </div>
      </main>
    </Page>
  );
}

export const query = graphql`
  query GetModeById($id: String) {
    modesJson(id: { eq: $id }) {
      id
      title
      shortDescription
      author
      repository
      npmPackageName
      installationCommand
      license
      builtIn
    }
  }
`;

export default Mode;
